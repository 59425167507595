(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function ($) {
  "use strict";

  $(document).ready(function () {
    init();
  });

  var init = function init() {
    addEventListeners();
  };

  var addEventListeners = function addEventListeners() {
    $('#form-language .language-select').on('click', handleLanguageSelect);
    $('#form-currency .currency-select').click(handleCurrencySelect);
  };
  /* Currency */


  var addCurrencySelectListener = function addCurrencySelectListener() {
    $('#form-currency .currency-select').on('click');
  };

  function handleCurrencySelect() {
    e.preventDefault();
    $('#form-currency input[name=\'code\']').val($(this).attr('name'));
    $('#form-currency').submit();
  }
  /* Currency / End */

  /* Language */


  function handleLanguageSelect(e) {
    e.preventDefault();
    $('#form-language input[name=\'code\']').val($(this).attr('name'));
    $('#form-language').submit();
  }

  ;
  /* Language / End */

  /* Shared Lib */

  $.fn.extend({
    scrollToTop: function scrollToTop() {
      var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 300;
      return $(this).animate({
        scrollTop: 0
      }, duration);
    }
  });
  $.extend($, {
    getURLVar: function getURLVar(key) {
      var value = [];
      var query = String(document.location).split('?');

      if (query[1]) {
        var part = query[1].split('&');

        for (var i = 0; i < part.length; i++) {
          var data = part[i].split('=');

          if (data[0] && data[1]) {
            value[data[0]] = data[1];
          }
        }

        if (value.hasOwnProperty(key)) {
          return value[key];
        } else {
          return '';
        }
      }
    }
  });
  $.cart = {
    $indicator: $('#indicator-cart').find('.indicator__value'),
    $indicatorMobile: $('#indicator-cart-mobile').find('.indicator__value'),
    $dropdown: $('#indicator-cart').find('.indicator__dropdown'),
    setIndicator: function setIndicator(value) {
      $.cart.$indicator.text(value);
      $.cart.$indicatorMobile.text(value);
    },
    add: function add(product_id, quantity) {
      $.ajax({
        url: 'index.php?route=checkout/cart/add',
        type: 'post',
        data: 'product_id=' + product_id + '&quantity=' + (typeof quantity != 'undefined' ? quantity : 1),
        dataType: 'json',
        success: function success(json) {
          $.alert.removeAll();

          if (json['redirect']) {
            location = json['redirect'];
          }

          if (json['success']) {
            $.alert.addGlobal(json['success'], 'success'); // Need to set timeout otherwise it wont update the total
            // setTimeout(function () {
            // 	$('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
            // }, 100);

            $.cart.setIndicator(json['total']);
            $('html, body').scrollToTop();
            $.cart.$dropdown.load('index.php?route=common/cart/info');
          }
        },
        error: function error(xhr, ajaxOptions, thrownError) {
          $.addGlobal('An internal server error occurred.', 'danger');
          console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
      });
    },
    remove: function remove(key) {
      $.ajax({
        url: 'index.php?route=checkout/cart/remove',
        type: 'post',
        data: 'key=' + key,
        dataType: 'json',
        success: function success(json) {
          // Need to set timeout otherwise it wont update the total
          // setTimeout(function () {
          // 	$('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
          // }, 100);
          $.cart.setIndicator(json['total']);
          $.cart.$dropdown.load('index.php?route=common/cart/info');
        },
        error: function error(xhr, ajaxOptions, thrownError) {
          console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
      });
    },
    update: function update(key, quantity) {
      $.ajax({
        url: 'index.php?route=checkout/cart/edit',
        type: 'post',
        data: 'key=' + key + '&quantity=' + (typeof quantity != 'undefined' ? quantity : 1),
        dataType: 'json',
        beforeSend: function beforeSend() {
          $('#cart > button').button('loading');
        },
        complete: function complete() {
          $('#cart > button').button('reset');
        },
        success: function success(json) {
          // Need to set timeout otherwise it wont update the total
          setTimeout(function () {
            $('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
          }, 100);

          if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
            location = 'index.php?route=checkout/cart';
          } else {
            $('#cart > ul').load('index.php?route=common/cart/info ul li');
          }
        },
        error: function error(xhr, ajaxOptions, thrownError) {
          console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
      });
    }
  };
  $.compare = {
    $indicator: $('#indicator-compare').find('.indicator__value'),
    $indicatorMobile: $('#indicator-compare-mobile').find('.indicator__value'),
    add: function add(product_id) {
      $.ajax({
        url: 'index.php?route=product/compare/add',
        type: 'post',
        data: 'product_id=' + product_id,
        dataType: 'json',
        success: function success(json) {
          $.alert.removeAll();

          if (json['success']) {
            $.alert.addGlobal(json['success'], 'success');
            $.compare.setIndicator(json['total']);
            $('html, body').scrollToTop();
          }
        },
        error: function error(xhr, ajaxOptions, thrownError) {
          console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
      });
    },
    setIndicator: function setIndicator(value) {
      $.compare.$indicator.text(value);
      $.compare.$indicatorMobile.text(value);
    }
  };
  $.alert = {
    getHtml: function getHtml(text, options) {
      var type = options.type || 'success';
      var id = options.id || false;
      var className = "alert alert-".concat(type, " alert-dismissible fade show");

      if (options.className) {
        className += " ".concat(options.className);
      }

      return "\n\t\t\t\t<div class=\"".concat(className, "\" ").concat(id ? 'id="' + id + '"' : '', ">\n\t\t\t\t\t").concat(text, "\n\t\t\t\t\t<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n\t\t\t\t\t\t<svg width=\"12px\" height=\"12px\">\n\t\t\t\t\t\t\t<use xlink:href=\"catalog/view/theme/default/assets/images/sprite.svg#cross-12\"></use>\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t</button>\n\t\t\t\t</div>\n\t\t\t");
    },
    add: function add($parent, text, options) {
      var id = Math.floor(Math.random() * Math.pow(10, 6));
      var alert = $.alert.getHtml(text, _objectSpread(_objectSpread({}, options), {}, {
        id: id,
        className: 'mt-3'
      }));
      $parent.append(alert);
      setTimeout(function () {
        $("#".concat(id)).fadeOut(500, function () {
          $(this).remove();
        });
      }, 5000);
    },
    addGlobal: function addGlobal(text, options) {
      var alert = $.alert.getHtml(text, _objectSpread(_objectSpread({}, options), {}, {
        className: 'mt-3'
      }));
      var id = Math.floor(Math.random() * Math.pow(10, 6));
      $('.site__body').prepend("\n\t\t\t\t<div class=\"alert-block\" id=\"".concat(id, "\">\n\t\t\t\t\t<div class=\"container\">\n\t\t\t\t\t\t").concat(alert, "\n\t\t\t\t\t</div>\t\t\t\t\t\n\t\t\t\t</div>\n\t\t\t"));
      setTimeout(function () {
        $("#".concat(id)).fadeOut(500, function () {
          $(this).remove();
        });
      }, 5000);
    },
    removeAll: function removeAll() {
      $('.alert-block').remove();
    },
    removeGlobal: function removeGlobal() {
      var $alertBlocks = $('.alert-block');

      if ($alertBlocks.length) {
        setTimeout(function () {
          $alertBlocks.fadeOut(500, function () {
            $(this).remove();
          });
        }, 5000);
      }
    }
  };
})(jQuery);

},{}]},{},[1]);
